import React, { useState } from "react";
import politico from "../assets/img/politico.webp";
import barcode from "../assets/img/barcode.webp";
import mud from "../assets/img/mud.webp";
import tiemendo from "../assets/img/tiemendo.webp";
import rag from "../assets/img/rag.webp";
import pure from "../assets/img/pureretail.webp";
import ip from "../assets/img/iplocation.png";

const Works = () => {
  return (
    <section className="container section visible" id="works">
      <div className="center" id="wrapper">
        <div className="content-container">
          <h4 className="header-text">Works</h4>
          <div className="works">
            <div className="grid">
              <div className="grid_item">
                <a
                  href="https://qrcode-zone.netlify.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <a href={barcode} className="progressive">
                    <img src={barcode} alt="images" />
                  </a>
                  <div className="overlay">
                    <div className="description">
                      <h4>QR Code Zone</h4>
                      <p className="type">
                        QR code zone is a platform built with serverless backend
                        (AWS) and Vuejs.You can simply create barcodes, scan
                        barcodes and save barcodes with QR code zone
                      </p>
                      <a
                        href="https://github.com/Rexben001/barcodes-FE"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-github" />
                      </a>
                      <a
                        href="https://qrcode-zone.netlify.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-globe" />
                      </a>
                    </div>
                  </div>
                </a>
              </div>

              <div className="grid_item">
                <a
                  href="https://pure-retail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <a href={pure} className="progressive">
                    <img src={pure} alt="images" />
                  </a>
                  <div className="overlay">
                    <div className="description">
                      <h4>Pure Retail</h4>
                      <p className="type">
                        Period. Pure-Retail is the easiest-to-use shopping cart
                        for everyone - no matter how much technical knowledge
                        you have. It's a mobile first design.
                      </p>
                      <a
                        href="https://github.com/LABS-EU3/shopping_cart_frontend"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-github" />
                      </a>
                      <a
                        href="https://pure-retail.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-globe" />
                      </a>
                    </div>
                  </div>
                </a>
              </div>

              <div className="grid_item">
                <a
                  href="https://rexben001.github.io/Politico/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <a href={politico} className="progressive">
                    <img src={politico} alt="images" />
                  </a>
                  <div className="overlay">
                    <div className="description">
                      <h4>Politico</h4>
                      <p className="type">
                        Politico enables citizens give their mandate to
                        politicians running for different government offices
                        while building trust in the process through
                        transparency. Cast your vote. One vote one person.
                      </p>
                      <a
                        href="https://github.com/Rexben001/Politico"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-github" />
                      </a>
                      <a
                        href="https://rexben001.github.io/Politico/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-globe" />
                      </a>
                    </div>
                  </div>
                </a>
              </div>
              <div className="grid_item">
                <a
                  href="https://tieme-ndo.netlify.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <a href={tiemendo} className="progressive">
                    <img src={tiemendo} alt="images" />
                  </a>
                  <div className="overlay">
                    <div className="description">
                      <h4>Tiemendo</h4>
                      <p className="type">
                        Tieme Ndo App is an in-house for Tieme Ndo
                        organizations. It is used by the CEO and staffs in the
                        organization to track farmers' records and yields. It
                        helps to keep track off all farmers' activities.{" "}
                      </p>
                      <a
                        href="https://github.com/tieme-ndo"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-github" />
                      </a>
                      <a
                        href="https://tieme-ndo.netlify.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-globe" />
                      </a>
                    </div>
                  </div>
                </a>
              </div>

              <div className="grid_item">
                <a
                  href="https://mud-game-lambda.netlify.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <a href={mud} className="progressive">
                    <img src={mud} alt="images" />
                  </a>
                  <div className="overlay">
                    <div className="description">
                      <h4>Mud Game</h4>
                      <p className="type">
                        A multiplayer real-time virtual world web application.
                        Players can transverse rooms (North, South, East, West),
                        view descriptions of rooms.
                      </p>
                      <a
                        href="https://github.com/CSEU2-BW/CS-Build-Week-1-FE"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-github" />
                      </a>
                      <a
                        href="https://mud-game-lambda.netlify.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-globe" />
                      </a>
                    </div>
                  </div>
                </a>
              </div>
              <div className="grid_item">
                <a
                  href="https://suspicious-bohr-8308ad.netlify.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <a href={rag} className="progressive">
                    <img src={rag} alt="images" />
                  </a>
                  <div className="overlay">
                    <div className="description">
                      <h4>Random Acts Generator</h4>
                      <p className="type">
                        Random Acts Generator is an app that reminds users to
                        perform an act of service to someone on their contact
                        list and also provides ideas for the user to perform in
                        situations where they can’t come up with one.
                      </p>
                      <a
                        href="https://github.com/random-acts-generator-eu/rag-frontend"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-github" />
                      </a>
                      <a
                        href="https://suspicious-bohr-8308ad.netlify.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-globe" />
                      </a>
                    </div>
                  </div>
                </a>
              </div>

              <div className="grid_item">
                <a
                  href="https://ip-location-finder.netlify.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <a href={ip} className="progressive">
                    <img src={ip} alt="images" />
                  </a>
                  <div className="overlay">
                    <div className="description">
                      <h4>IP Location Tracker</h4>
                      <p className="type">
                        IP Location Tracker is an App build to get the details
                        of a location provided you supply a correct IP address
                      </p>
                      <a
                        href="https://github.com/Rexben001/ipLocationTracker"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-github" />
                      </a>
                      <a
                        href="https://ip-location-finder.netlify.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-globe" />
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Works;
