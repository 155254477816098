import React from "react";
import TypeWriter from "react-typist";
import Profile from "../assets/img/my.jpg";

const Homepage = () => {
  return (
    <div className="parent">
      <div id="home">
        <div className="row-flex">
          <div className="content-me">
            <TypeWriter className="intro-text">
              <p className="hello">
                Hello
                <span role="img" aria-label="Smile" className="emoji">
                  &nbsp; &#128578;
                </span>
                ,
              </p>

              <span className="my-name">
                My name is Benjamin Ajewole (Rexben)
              </span>
              <br />
              <br />
              <span className="my-title">A Software Developer</span>
              <TypeWriter.Backspace count={9} delay={200} />
              <span className="my-title">
                Engineer with a demonstrated history of building software
                solutions.
              </span>
            </TypeWriter>

            <div className="socials">
              <a
                href="https://twitter.com/rexben001"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter page"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                href="https://www.linkedin.com/in/rexben/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn page"
              >
                <i className="fab fa-linkedin" />
              </a>
              <a
                href="https://github.com/Rexben001/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="GitHub page"
              >
                <i className="fab fa-github" />
              </a>
              <a
                href="mailto:rexben.rb@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Email"
              >
                <i className="fa fa-envelope" />
              </a>
              <a
                href="https://medium.com/@rexben"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Medium"
              >
                <i className="fab fa-medium" />
              </a>
            </div>
            {/* <button className='home-btn'>
              <a href='../assets/my-cv.pdf' download='my-cv.pdf'>
                Download CV
              </a>
            </button> */}
          </div>
          <div className="my-image">
            <img
              // src='https://res.cloudinary.com/rexben/image/upload/q_auto/v1570222714/porfolio/rexben-bg.jpg'
              className="profile"
              src={Profile}
              alt=""
            />
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Homepage;
