import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailJs from "emailjs-com";

class Contact extends Component {
  state = {
    from_name: "",
    reply_to: "",
    message_html: "",
    success: "",
    time: "",
    to_name: "Rexben",
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { from_name, reply_to, message_html } = this.state;
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (reply_to && !re.test(reply_to)) {
      toast.error("Enter the valid email address");
    }
    if (from_name.length < 3) {
      toast.error("Name length should not be less than 2");
    }
    if (message_html.length < 3) {
      toast.error("Message length should not be less than 2");
    }
    if (
      from_name !== "" &&
      reply_to !== "" &&
      message_html !== "" &&
      from_name.length > 3 &&
      message_html.length > 3
    ) {
      EmailJs.init("user_Kv4jWuXFfCCpKQ4tnq85h");
      EmailJs.sendForm(
        "gmail-rexben",
        "template_FfsI2VML",
        document.querySelector("form")
      ) //use your Service ID and Template ID
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
            window.location.href = "/";
          },
          function (error) {
            console.log("FAILED...", error);
            return toast.error("Unable to send message");
          }
        );
      this.setState({ name: "", email: "", message: "", success: "" });
    }
    // else {
    // }
  };

  // Initialize Firebase

  inputHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { to_name, from_name, reply_to, message_html } = this.state;
    return (
      <section className="container section visible" id="contact">
        <div className="center" id="wrapper">
          <div className="content-container">
            <div className="contact-page">
              <h4 className="header-text">Contact Me</h4>
              <div className="contact-me">
                <form
                  className="my-form"
                  name="form"
                  onSubmit={this.submitHandler}
                >
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    name="from_name"
                    onChange={this.inputHandler}
                    placeholder="Your name"
                    value={from_name}
                    // required
                  />
                  <input
                    type="text"
                    name="to_name"
                    onChange={this.inputHandler}
                    placeholder="Your name"
                    value={to_name}
                    // required
                    hidden
                  />
                  <label htmlFor="">Email</label>
                  <input
                    type="email"
                    name="reply_to"
                    onChange={this.inputHandler}
                    placeholder="Your email"
                    value={reply_to}
                    // required
                  />
                  <label htmlFor="Message">Message</label>
                  <textarea
                    name="message_html"
                    id=""
                    cols="30"
                    rows="10"
                    onChange={this.inputHandler}
                    placeholder="Your message"
                    value={message_html}
                    // required
                  />
                  <button type="submit" className="submit">
                    Submit
                  </button>
                </form>
                <div className="sideNote"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
