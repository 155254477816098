import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = (props) => {
  const loc = props.history.location.pathname;
  return (
    <nav>
      <main className='nav sidebar'>
        <NavLink
          to='/'
          className={loc === '/' ? 'centre logo selected' : 'centre logo'}
          data-balloon-pos='right'
          data-balloon='Home'
          data-toggle='#home'
          aria-label='Homepage'
        >
          <span className='nav-fas'>
            <i className='fas fa-home' /> <br /> <br />{' '}
            <span className='nav-name'>Home</span>
          </span>
        </NavLink>
        <NavLink
          to='/about'
          className={loc === '/about' ? 'centre logo selected' : 'centre logo'}
          data-balloon-pos='right'
          data-balloon='About'
          data-toggle='#about'
          aria-label='About page'
        >
          <span className='nav-fas'>
            <i className='far fa-user' /> <br /> <br />{' '}
            <span className='nav-name'>About</span>
          </span>
        </NavLink>
        <NavLink
          to='/works'
          className={loc === '/works' ? 'centre logo selected' : 'centre logo'}
          data-balloon-pos='right'
          data-balloon='Works'
          data-toggle='#works'
          aria-label='Works page'
        >
          <span className='nav-fas'>
            <i className='fas fa-briefcase' /> <br /> <br />{' '}
            <span className='nav-name'>Works</span>
          </span>
        </NavLink>
        <NavLink
          to='/blog'
          className={loc === '/blog' ? 'centre logo selected' : 'centre logo'}
          data-balloon-pos='right'
          data-balloon='Blog'
          data-toggle='#blog'
          aria-label='Blog page'
        >
          <span className='nav-fas'>
            <i className='fas fa-blog'></i>
            <br /> <br /> <span className='nav-name'>Blog</span>
          </span>
        </NavLink>
        <NavLink
          to='/contact'
          className={
            loc === '/contact' ? 'centre logo selected' : 'centre logo'
          }
          data-balloon-pos='right'
          data-balloon='Contact'
          data-toggle='#contact'
          aria-label='Contact page'
        >
          <span className='nav-fas'>
            <i className='fa fa-envelope' /> <br /> <br />{' '}
            <span className='nav-name'>Contact</span>
          </span>
        </NavLink>
      </main>
    </nav>
  );
};

export default Navbar;
