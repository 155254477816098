import React, { useEffect, useState } from "react";
import moment from "moment";

const oldPosts = [
  {
    link: "https://rexben.medium.com/introduction-to-web-scraping-with-puppeteer-1465b89fcf0b",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:580/format:webp/1*kk8ovQKB-45FsZ8TZM-vjg.png",
    pubDate: "2020-03-05 15:12:02",
    title: "Introduction to web scraping with Puppeteer",
  },
  {
    link: "https://rexben.medium.com/setting-up-travis-ci-coveralls-and-code-climate-in-your-project-389f4ab4d2cb",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:1284/format:webp/1*ReJCeRt3UrdFp65T8mWs1A.png",
    pubDate: "2020-01-25 15:12:02",
    title:
      "How to setup Travis CI, Coveralls and Code Climate with your Nodejs project",
  },
  {
    link: "https://rexben.medium.com/how-to-deploy-your-node-api-on-heroku-c99f3f78ecc1",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*N6ojB_VgECHwVgpoG_c1Yw.png",
    pubDate: "2020-01-22 15:12:02",
    title: "How to deploy your Node API on Heroku",
  },
  {
    link: "https://rexben.medium.com/how-to-document-your-api-with-postman-67153bbdcb28",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:816/format:webp/1*1NvtVpZMFB0fXUDp2qEJBQ.png",
    pubDate: "2020-01-22 15:12:02",
    title: "How to document your API with Postman",
  },
  {
    link: "https://rexben.medium.com/getting-started-with-mongodb-mongoose-2a6acbc34dd4",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:4800/format:webp/1*xwhODuWn2A5GVeF64dyrHg.jpeg",
    pubDate: "2019-12-13 15:12:02",
    title: "Getting started with MongoDB/Mongoose",
  },
  {
    link: "https://rexben.medium.com/how-to-fix-page-not-found-on-netlify-with-react-router-dom-e0520692be5",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*gEHvLfiYW82BFdSOfAR5kw.png",
    pubDate: "2019-02-05 15:12:02",
    title: "How to fix page not found on Netlify with react-router-dom",
  },
  {
    link: "https://rexben.medium.com/challenges-are-meant-to-make-you-and-not-to-mar-you-4df5735312ed",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:4800/format:webp/1*tbf2ieIosYOoXeKni3yBoA.jpeg",
    pubDate: "2019-02-05 15:12:02",
    title: "Challenges are meant to make you and not to mar you",
  },
  {
    link: "https://rexben.medium.com/how-i-demonstrated-growth-mindset-recently-f56aada14e6b",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*UZ-afjPjxxOqMkAIeCHhmw.jpeg",
    pubDate: "2019-01-28 15:12:02",
    title: "How I demonstrated growth mindset recently",
  },
  {
    link: "https://medium.com/@rexben/new-to-git-and-github-b28afa57f65d",
    thumbnail: "https://miro.medium.com/max/700/1*dBCtU6W-2NpucI9lxzEXAQ.png",
    pubDate: "2018-12-14 15:12:02",
    title: "New to Git and GitHub?",
  },
  {
    link: "https://medium.com/@rexben/a-to-z-of-software-development-e7a8ca23336f",
    thumbnail: "https://miro.medium.com/max/640/1*2WDyq1dMw4xU5CYS-5P2Zw.jpeg",
    pubDate: "2018-12-14 15:12:02",
    title: "A to Z of Software Development",
  },
  {
    link: "https://medium.com/@rexben/write-code-the-right-way-62637a8b2b5b",
    thumbnail: "https://miro.medium.com/max/640/1*ZeDvhI2lKhC-AQWWeNnXXg.jpeg",
    pubDate: "2018-12-13 15:12:02",
    title: "Write code the right way",
  },
  {
    link: "https://medium.com/@rexben/hello-world-in-15-programming-languages-548328e62211",
    thumbnail: "https://miro.medium.com/max/700/1*Y4iC7OpjtP5Le9aTDlA5Ig.jpeg",
    pubDate: "2018-12-13 15:12:02",
    title: "Hello world in 15 programming languages",
  },
  {
    link: "https://rexben.medium.com/challenges-are-stepping-stones-and-not-stumbling-blocks-cc8e4da3eeb",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:4800/format:webp/1*tbf2ieIosYOoXeKni3yBoA.jpeg",
    pubDate: "2018-11-21 15:12:02",
    title: "Challenges are stepping stones and not stumbling blocks",
  },
  {
    link: "https://medium.com/@rexben/your-smartphone-is-actually-smart-96a990284394",
    thumbnail: "https://miro.medium.com/max/700/1*dNS0GmvYjn-4ScFxtVq0dQ.jpeg",
    pubDate: "2018-04-20 15:12:02",
    title: "YOUR SMARTPHONE IS ACTUALLY SMART",
  },
];

const getImageUrl = (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  const imageElement = doc.querySelector("figure img");
  const imageUrl = imageElement.getAttribute("src");

  return imageUrl;
};

const Medium = () => {
  const [state, setState] = useState(oldPosts);

  useEffect(() => {
    // window.mediumWidget();
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@rexben"
    )
      .then((res) => res.json())
      .then((data) => {
        // Fillter the array
        const res = data.items; //This is an array with the content. No feed, no info about author etc..
        const posts = res.filter((item) => item.categories.length > 0);

        const updatedPosts = posts.map((post) => ({
          ...post,
          thumbnail: getImageUrl(post.description),
        }));
        console.log({ updatedPosts });
        setState([...updatedPosts, ...state]);
      });
  }, []);
  return (
    <section className="container section visible" id="about">
      <div className="center" id="wrapper">
        <div
          className="content-container"
          style={{
            width: "80%",
          }}
        >
          <h4 className="header-text">Blog ({state.length})</h4>
          <div className="works">
            <div
              className="grid"
              style={{
                justifyContent: "space-between",
              }}
            >
              {state.length
                ? state.map((post) => (
                    <div
                      className="grid_item blog_grid"
                      key={post.title}
                      style={{
                        marginBottom: "30px",
                      }}
                    >
                      <a
                        href={post.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={post.thumbnail}
                          style={{
                            height: "200px",
                            objectFit: "cover",
                            width: "100%",
                          }}
                          alt="blog thumbnail"
                        />
                        <p className="blogText">{post.title}</p>
                        <p
                          style={{
                            textAlign: "right",
                            padding: "0",
                            margin: "0",
                          }}
                        >
                          {moment(post.pubDate).format("LL")}
                        </p>
                      </a>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Medium;
