import React from 'react';

const NotFound = () => {
  return (
    <section className='section visible' id='about'>
      <div className='center' id='wrapper'>
        <div className='content-container'>
          <div className='error-page'>
            <div>404</div>
            <div>Page not found</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
