import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWindow from "./useWindow";

import Navbar from "./components/Navbar";
import Hompeage from "./components/Homepage";
import About from "./components/About";
import Works from "./components/Works";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound";
import Blog from "./components/Blog";

function App() {
  const size = useWindow();

  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  return (
    <main
      className="App"
      style={{
        position: "relative",
      }}
    >
      <Router>
        <div>
          {size.width >= 540 && <Route component={Navbar} />}
          <div
            className="AppMain"
            style={{
              position: "absolute",
              top: "0%",
              width: "100%",
              zIndex: "4000",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "0%",
                width: "100%",
                zIndex: "4000",
              }}
            >
              <p className="cancel" onClick={handleClick}>
                {!click ? "☰" : "X"}
              </p>

              {click && (
                <ul className="mainMenu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/works">Works</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <Switch>
            <Route exact path="/" component={Hompeage} />
            <Route exact path="/about" component={About} />
            <Route exact path="/works" component={Works} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </div>
        <ToastContainer position="top-right" />
      </Router>
    </main>
  );
}

export default App;
