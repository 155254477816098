import React from "react";

const About = () => {
  return (
    <section className="container section visible" id="about">
      <div className="center" id="wrapper">
        <div className="content-container">
          <h4 className="header-text">About Me</h4>
          <p>
            Experienced Software Engineer with a demonstrated history of
            building software solutions. Knowledgeable in building full-stack
            applications and using VCS. Able to work independently and as well
            working in a team. <br />
            A certified AWS Solution Architect.
            <br />
            I'm an Open source contributor. I have contributed to several
            open-source projects including open collective, oh-my-zsh. <br />I
            love playing board games especially Scrabble, Monopoly and Chess. I
            enjoy playing street soccer and watching football matches. <br />I
            love helping and mentoring newbies and also write technical
            articles.
          </p>

          <div className="hr" />
          <div className="skills">
            <h4>Technologies</h4>

            <div className="skill-category">
              <div className="flex">
                <div className="flex-1">
                  <div className="skill">
                    <h4 className="skill-name">
                      {" "}
                      <i className="devicon-html5-plain"></i>
                      &nbsp; HTML5 &&nbsp;
                      <i className="devicon-css3-plain"></i>
                      CSS 3
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      <i className="devicon-nodejs-plain"></i>&nbsp; Nodejs
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      <i className="devicon-python-plain"></i>
                      &nbsp; Python
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      <i className="devicon-typescript-plain"></i>&nbsp;
                      Typescript
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      <i className="devicon-typescript-plain"></i>&nbsp; AWS
                    </h4>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="skill">
                    <h4 className="skill-name">
                      <i className="devicon-react-original"></i>
                      &nbsp; React
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      <i className="devicon-postgresql-plain"></i>&nbsp; VueJS
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      {" "}
                      <i className="devicon-mysql-plain"></i>
                      &nbsp; MySQL
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      <i className="devicon-mongodb-plain"></i>&nbsp; MongoDB
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      <i className="devicon-mongodb-plain"></i>&nbsp; Postgres
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hr" />
          <div className="skills">
            <h4>Skills</h4>

            <div className="skill-category">
              <div className="flex">
                <div className="flex-1">
                  <div className="skill">
                    <h4 className="skill-name">&#11044; &nbsp; Mentoring</h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      &#11044; &nbsp; Technical Writing
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      &#11044; &nbsp; Communication and Empathy
                    </h4>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="skill">
                    <h4 className="skill-name"> &#11044; &nbsp; Coaching</h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      &#11044; &nbsp; Problem solving
                    </h4>
                  </div>
                  <div className="skill">
                    <h4 className="skill-name">
                      &#11044; &nbsp; Self Motivation
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
